
import { defineComponent } from 'vue'
import WelcomeLogo from '@/assets/svg/welcome-logo.svg?inline'

export default defineComponent({
  name: 'WelcomeSection',
  data () {
    return {
    }
  },
  components: {
    WelcomeLogo
  },
  methods: {
  }
})
